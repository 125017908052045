html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  body{
    background-image: linear-gradient(
        0deg, 
        rgba(124, 187, 0, 0.8) -61.89%, 
        rgba(0, 161, 241, 0.8) 75.53%, 
        rgba(0, 161, 241, 0.8) 86.22%
      ),url('../images/desktop_background_img.webp');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-attachment: fixed;
  }
  section{
    height: 100vh;
    display: flex; /* Optional: center content */
      justify-content: center; /* Optional: horizontally center content */
      align-items: center; 
  }


@import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap";

@import './setting/font';


@import './component/Auth.scss';
@import './component/Global.scss';