.space-60 {
    width: 100%;
    height: 60px;
}
.space-20 {
    width: 100%;
    height: 20px;
}
.space-90 {
    width: 100%;
    height: 90px;
}
.space-5 {
    width: 100%;
    height: 5px;
}
.space-10 {
    width: 100%;
    height: 10px;
}
.error{
    color: red;
}
a{
    text-decoration: none;
}