.finance-banner{
    // background-color: #000;

    .finance-banner-row{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        
    }
    
    h6{
        color: #FFFFFF !important;
        font-family: $font-family-primary !important;
        font-size: 16px;
        font-weight: 500 !important;
        text-transform: uppercase;
        margin-bottom: 28px;
    }
    h2{
        color: #FFFFFF !important;
        font-family: $font-family-2 !important;
        font-size: 60px;
        font-weight: 400 !important;
        margin-bottom: 23px;
    }
    p{
        color: #FFFFFF;
        font-family: $font-family-2;
        font-size: 18px;
        font-weight: 400 !important;
        margin-bottom: 40px;
    }
    .main-form{
        position: relative;
    }
    .finance-quote-form {
        padding: 5%;
        background-color: #fff;
        border-radius: 5px;
        // height: 94vh;
        margin: 5%;
        width: 100%;
        img{
            width: 200px;
        }
        h3{
            color: #1B1D21;
            font-family: $font-family-primary;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 0;
            text-align: left;
        }
        .wlcome-text{
            color: #061E2F;
            font-family: $font-family-2;
            font-size: 0.875rem;
            font-weight: 400;
            font-style: normal;
            text-decoration-line: none;
            line-height: 1.25rem;
            letter-spacing: 0px;
            margin: 0px;
            padding: 0px;
        }
        .shopersbay-text{
            color: #061E2F;
            font-family: $font-family-2;
            font-size: 0.875rem;
            font-weight: 600;
            font-style: normal;
            text-decoration-line: none;
            line-height: 1.25rem;
            letter-spacing: 0px;
            margin: 0px;
            padding: 0px;
        }
        .agree-text{
            color: #061E2F;
            font-family: $font-family-2;
            font-size: 0.75rem;
            font-weight: 400;
            font-style: normal;
            text-decoration-line: none;
            line-height: 1.125rem;
            letter-spacing: 0px;
            text-align: center;
            margin: 0px;
            padding: 0px;
        }
        a{
            text-decoration: none;
        }
        .back-module{
            cursor: pointer;
        }

        .main-contact-form{
            box-shadow: none;
            background: none;
            border-radius: 0;
            padding: 0;
            input[type="text"],input[type="password"], input[type="email"], textarea, select{
                width: 100%;
                color: #666666;
                border: 1px solid #dbdbdb;
                padding: 5px;
                box-sizing: border-box;
                outline: none;
                font-size: 14px;
                border-radius: 3px;
                &:focus{
                    outline: 0;
                    box-shadow: none;
                }
            }
            input[type="checkbox"]{
                &:focus{
                    border-color: $primary-color;
                    box-shadow: 0 0 5px $shade_background;
                }
                &:checked{
                    border-color: $primary-color;
                    background-color: $primary-color;
                    box-shadow: 0 0 5px $shade_background;
                }
            }

            .form-group {
                .country-code {
                    border-radius: 3px 0 0 3px; /* Rounded left corners */
                    padding: 5px;
                    font-size: 14px;
                    border: 1px solid #ccc;
                    flex: .2;
                   &:focus{
                    border-color: #ccc; /* Maintain border color as it is */
                    box-shadow: none; /* Remove the blue box-shadow */
                   }
                  }
                  
                  .phone-textbox {
                    border-radius: 0 3px 3px 0;
                    padding: 5px;
                    border: 1px solid #ccc;
                    flex: 1 1;
                    font-size: 14px;
                    &:focus{
                        border-color: #ccc; /* Maintain border color as it is */
                        box-shadow: none; /* Remove the blue box-shadow */
                       }
                  }
               
              }
              .form-check{
                font-size: 14px;
              }
              .form-check-input{
                border: 1px solid $primary-color;
              }

            
        }
       .octf-btn {
            background-color: $primary-color;
            color: #fff;
            border: 1px solid $primary-color;
            width: 50%;
            padding: 5px;
            &:hover{
                background-color: #00A1F1d6;
                border: 1px solid #00A1F1;
            }
        }

    }
    .padding-login{
        padding: 10%;
    }
}

.module-section{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    .container {
        img{
            width: 200px;
        }
        .parent-card{
            background: #f7f7f8;
            label{
                padding-left: 8px;
                padding-right: 8px;
                padding-bottom: 8px;
                cursor: pointer;
                box-shadow: none;
                .card{
                    border: none;
                    border-top: 1px solid #F3F4F6;
                    user-select: none;
                    transition: all 0.2s ease-in-out;
                    overflow: hidden;
                }
            }
        }
        .toggle-section{
            border: 1px solid rgba(124, 187, 0, 0.2);
            background-color: rgba(124, 187, 0, 0.1);
            border-radius: 10px;
            padding: 0px 10px 5px 5px;
        }
        ul{
            // padding-left: 10px;
        }
        button{
            background-color: #00A1F1;
            color: #fff;
            border: none;
            padding: 5px 20px;
            border-radius: 5px;
            &:hover{
                background-color: #00A1F1d6;
                border: 1px solid #00A1F1;
            }
        }
        .module_name {
            h6{
                margin: 0;
            }
        }
        .animated-card {
            // opacity: 0;
            transform: scale(0.8);
            transition: all 0.5s ease-in-out;
            animation: insideOut 0.6s forwards;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
            li{
                font-size: 13px;
                font-weight: 300;
            }
            h6{
                font-size: 15px;
            }
        }
        .shift-right{
            transform: translateX(200px);
        }
        .ot-switcher{

            span {
                font-size: 14px;
                font-weight: 400;
                vertical-align: middle;
            }
            .switch {
                display: inline-block;
                height: 20px;
                margin: 0 7px;
                position: relative;
                width: 40px;
                top: 5px;
            }
            .slider.round {
                border-radius: 16px;
                &::before{
                    border-radius: 50%;
                }
            }
            .slider {
                
                bottom: 0;
                cursor: pointer;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                transition: .4s;
                &::before{
                    background-color: #00A1F1;
                    bottom: 2px;
                    content: "";
                    height: 14px;
                    left: 4px;
                    position: absolute;
                    transition: .4s;
                    width: 14px;
                }
            }
            .activeclass{
                &::before {
                    left: unset !important;
                    right: 3px;
                    background-color: #fff;
                    
                }
            }
            .activebg{
                background-color: #00A1F1;
                border: 1px solid #00A1F1;

            }
            .inactivebg{
                background-color: #fff;
                border: 1px solid #00A1F1;
            }
            
        }
        .spinner-border{
            position: absolute;
            left: 50%;
            top: 40%;
            color: $primary_color;
        }
    }
     
}

@keyframes insideOut {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    50% {
      opacity: 0.5;
      transform: scale(1.1);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

.forgot-password{
    text-align: center;
    cursor: pointer;
    color: $primary-color;

}
.select-plan{
    
    background-color: #827e7e;
}
.title-text{
    padding: 0px 0px 0px 80px;
}
.account-text{
    color: #061E2F;
    font-family: "Open Sans", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    font-style: normal;
    text-decoration-line: none;
    line-height: 1.25rem;
    letter-spacing: 0px;
    margin: 0px;
    padding: 0px;
}
.redirect-text{
    color: $primary-color;
    cursor: pointer;
}
.sign-in-section{
    text-align: center;
}
.custom-spinners{
    width: 20px;
    height: 20px;
    border-width: 3px;
    color: #fff;
    top: 10px;
    margin-top: 19px;
}
.password-div{
    display: flex;
    position: relative;
    svg{
        position: absolute;
        right: 15px;
        top:30%;
        font-size: 20px;
        cursor: pointer;
    }
}
.remenber{
    text-align: center;
    p{
        color: #000;
        font-size: 12px;
        a{
            // text-decoration: none;
            color: $primary-color;
            font-weight: 600;
            cursor: pointer;
        }
    }
}

@media only screen and (max-width: 768px) {
    .title-text{
        padding: 5%;
        height: 235px;
    }
    .finance-banner h2 {
        font-size: 40px;
    }
    .sign-in-section{
        text-align: center;
    }
    .forgot-password{
        text-align: center;
    }
}
.tooltip-inner{
background: #FFFFFF !important;
color: #000 !important;
}
.tooltip{

}
.ddddd{
    width: 400px !important;
    text-align: start;
    opacity: 1,
}
.selected_discount{
    font-size: 10px;
    background-color: rgba(124, 187, 0, 0.1);
    padding: 0px 2px;
    border: 1px solid rgba(124, 187, 0, 0.2);
    border-radius: 5px;
    font-weight: 600;
}
.selecteddataa{
    font-size: 12px;
}
.bottomlink{
    align-items: center;
}
.signin_pricepage{
    color: #00A1F1;
    cursor: pointer;
}